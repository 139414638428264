<template>
    <div class="page-main">
        <div class="main-banner">
            <img src="../../assets/image/main-page/function_banner.jpg" alt="">
            <div class="banner-text">
                <p class="tit">功能特色</p>
                <p class="tit-en">Functional features</p>
            </div>
        </div>
        <div class="module-single gray-single">
            <div class="module-main">
                <div class="module-tit-group">
                    <p class="tit">功能特色</p>
                    <p class="tit-en">Functional features</p>
                    <span class="line"></span>
                </div>
                <ul class="function-lists">
                    <li>
                        <div class="img">
                            <img src="../../assets/image/main-page/function_icon1.jpg" alt="">
                        </div>
                        <div class="text-group">
                            <p class="tit">数字化交易工具</p>
                            <p class="describe">降低运营成本，提高工作效率是每一个电商平台的目标。云搬砖从线上交易出发，整合线上签约线上支付功能。未来将推出线上开票，线上做账功能，极大提高签约和支付效率，增大了操盘手的全链条的掌握。线上合同管控合同模板，降低签约风险；公章上交，使用电子签章，真正实现一只笔，防范公章滥用。线上支付支持平台托管，解决双方第一次交易的信任问题。银行优盘上交，付款须老板一键确认，省却付款申请签字盖章审批等繁琐过程。</p>
                        </div>
                    </li>
                    <li>
                        <div class="img">
                            <img src="../../assets/image/main-page/function_icon2.jpg" alt="">
                        </div>
                        <div class="text-group">
                            <p class="tit">数字化营销工具</p>
                            <p class="describe">随着5G智能手机的推广，手机已经开始全面取代了电脑的进程。移动互联网的时代来了，你的官网还在电脑上慢腾腾的打开？你还在漫天撒网做搜索推广？你有多久没开过电脑了？你知道的客户的电脑已经长蜘蛛网了么？他们都用手机上班啦！所以你需要新一代的解决方案--微配云店，移动互联网时代的企业官网。关键是现在还不要钱。形象展台永不落幕，一键分享贵司风采；微官宣官方宣布相关信息，再无李鬼；在线商情时刻展现贵司供需状况，方便客户下单。</p>
                        </div>
                    </li>
                    <li>
                        <div class="img">
                            <img src="../../assets/image/main-page/function_icon3.jpg" alt="">
                        </div>
                        <div class="text-group">
                            <p class="tit">数字化管理工具</p>
                            <p class="describe">平台推出库存管理、头寸管理功能，即时准确反馈库存与头寸，并设立了库存警戒、头寸警戒功能，超过公司头寸允许不得进行签约等操作，避免了操盘手违规高风险赌博。员工根据授权，匹配现实岗位，分别执行采购、销售、物流、财务等智能，在公司允许的额度范围内发盘接盘。并且每日8点复盘，自动汇报工作过程和结果。并比对系统均价管理，厘清员工个人能力与平台优势的界线。采购均价低于均价和销售均价高于均价的，我们判定为跑赢均价；反之跑输均价。</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "function-page",
        components: {

        },
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/base";
    @import "../../assets/css/main-module";
    .function-lists{
        @include flex-between;
        margin-top: 76px;
        li{
            width: 370px;
            height: 600px;
            .img{
                width: 370px;
                height: 270px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .text-group{
                width: 100%;
                height: 330px;
                box-sizing: border-box;
                border: 1px solid #e5e5e5;
                border-top: none;
                padding: 28px 36px 0;
                .tit{
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 700;
                    text-align: center;
                }
                .describe{
                    font-size: 14px;
                    line-height: 24px;
                    color: #999;
                    margin-top: 13px;
                }
            }
        }
    }
</style>
